<template>
  <div class="ant-hx-table">
    <!--搜索条件 -->
    <div class="ant-hx-table-search">
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-input v-model:value="searchForm.name" placeholder="选择城市" />
        </a-form-item>
        <a-form-item>
          <a-space>
            <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch"
                      :disabled="loading">
              查询
            </a-button>
            <a-button @click="reset">
              重置
            </a-button>
          </a-space>
        </a-form-item>

      </a-form>
    </div>
    <a-card :bodyStyle="{'padding':'0px' ,'border-radius': '0px'}" ref="card">
      <a-row>
        <a-col :span="8" > <div class="choiceCity" :class="listDataArray[0]===true ? 'choiceCityBorder' :''"><ChoiceCity  ref="choiceCityRef1" :cityType="1" /></div> </a-col>
        <a-col :span="8" > <div class="choiceCity" :class="listDataArray[1]===true ? 'choiceCityBorder' :''"><ChoiceCity  ref="choiceCityRef2" :cityType="2" /></div> </a-col>
        <a-col :span="8" > <div class="choiceCity" :class="listDataArray[2]===true ? 'choiceCityBorder' :''"><ChoiceCity  ref="choiceCityRef3" :cityType="3" /></div> </a-col>
        <a-col :span="8" > <div class="choiceCity" :class="listDataArray[3]===true ? 'choiceCityBorder' :''"><ChoiceCity  ref="choiceCityRef4" :cityType="4" /></div> </a-col>
        <a-col :span="8" > <div class="choiceCity" :class="listDataArray[4]===true ? 'choiceCityBorder' :''"><ChoiceCity  ref="choiceCityRef5" :cityType="5" /></div> </a-col>

      </a-row>
    </a-card>
  </div>
</template>
<script>
import { reactive, ref, toRefs } from 'vue'
import { list } from '@/api/carrier/gradeCEnd'
import ChoiceCity from '../../../components/choiceCityCEnd/index'
export default {
  components: {
    ChoiceCity
  },
  setup () {
    const state = reactive({
      loading: false,
      listData: [],
      listDataArray: [false, false, false, false, false],
      searchForm: {},
      pagination: {
        total: 500,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const choiceCityRef1 = ref()
    const choiceCityRef2 = ref()
    const choiceCityRef3 = ref()
    const choiceCityRef4 = ref()
    const choiceCityRef5 = ref()
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = () => {
      state.loading = true
      list({ name: state.searchForm.name }).then(res => {
        if (res.code === 10000) {
          if (state.searchForm.name !== undefined && state.searchForm.name !== '') {
            state.listData = []
            state.listDataArray = [false, false, false, false, false]
            res.data.forEach(e => {
              state.listDataArray[(e.level - 1)] = true
            })
          }
          choiceCityRef1.value.loadData()
          choiceCityRef2.value.loadData()
          choiceCityRef3.value.loadData()
          choiceCityRef4.value.loadData()
          choiceCityRef5.value.loadData()
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = { }
      state.listDataArray = [false, false, false, false, false]
      loadData()
    }

    // 模糊搜索
    const filter = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }

    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      handleTableChange,
      filter,
      choiceCityRef1,
      choiceCityRef2,
      choiceCityRef3,
      choiceCityRef4,
      choiceCityRef5
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../TableTool.less';
</style>
