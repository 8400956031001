<template>
  <div class="city">
      <div class="city-head">
        <div>{{title}}</div>
        <div @click="onStartingAddress(),show= true" class="city-head-config">配置城市</div>
      </div>
    <div class="city-body">
      {{cityArraySting}}
    </div>
    <a-modal destroyOnClose :bodyStyle="{'padding':'0px'}"  width="1200px" :footer="null" v-model:visible="show" @cancel="searchForm.start=[]" :title="title+'配置'" >
        <div class="modal">
          <div class="modal-search">
            <a-form :model="searchForm" layout="vertical">
              <a-form-item label="选择城市">
                <a-cascader v-model:value="searchForm.start" :options="cityList"
                    @change="setStartAddress"  :changeOnSelect="true" :show-search="{ filter }" placeholder="选择城市"/>
              </a-form-item>
            </a-form>
          </div>
          <div class="modal-body">
            <div class="modal-body-check">
              <a-checkbox-group  @change="onCityCheckListChange()" style="width: 550px" v-model:value="cityCheckList" >
                  <a-row  >
                    <a-col :span="8" v-for="item in startingAddress" :key="item.cityId">
                      <a-checkbox @click="cityCheckId=item.cityId" :value="item.cityId">{{item.name}}</a-checkbox>
                    </a-col>
                  </a-row>
              </a-checkbox-group>
            </div>
            <div class="modal-body-bottom">
            <div>
<!--              <a-checkbox-->
<!--                v-model:checked="checkAll"-->
<!--                :indeterminate="indeterminate"-->
<!--                @change="onCheckAllChange"-->
<!--              >-->
<!--                全选-->
<!--              </a-checkbox>-->
              </div>
              <div>
                <a-space>
                  <a-button @click="reset">
                    取消
                  </a-button>
                  <a-button :loading="loading" type="primary" @click="onOk"
                            :disabled="loading">
                    确认
                  </a-button>
                </a-space>
              </div>
            </div>
          </div>
          <div class="modal-cityShow">
            <div  class="modal-cityShow-h">已选择城市</div>
            <a-checkbox-group @change="onCityCheckListChange()" style="width: 330px"  v-model:value="cityCheckList" >
              <a-row  >
                <a-col :span="12" v-for="item in listDataCheck" :key="item.cityId">
                  <a-checkbox @click="cityCheckId=item.cityId" :value="item.cityId">{{item.name}}</a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>

        </div>
    </a-modal>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { list, edit } from '@/api/carrier/gradeCEnd'
import { message } from 'ant-design-vue'
export default {
  props: {
    cityType: {
      type: Number
    }
  },
  setup (props) {
    const store = useStore()
    const state = reactive({
      sortSize: 0,
      title: '城市',
      checkAll: false,
      indeterminate: false,
      cityCheckList: [],
      cityCheckId: null,
      cityCheckListCache: [],
      cityType: Number,
      searchForm: {
        start: []
      },
      cityList: store.state.app.PClist,
      startingAddress: [],
      cityArraySting: '',
      loading: false,
      listData: [],
      listDataCheck: [],
      show: false
    })
    const loadData = () => {
      state.loading = true
      list({
        levelList: [state.cityType]
      }).then(res => {
        if (res.code === 10000) {
          state.cityArraySting = ''
          state.cityCheckList = []
          state.cityCheckListCache = []
          res.data.forEach(e => {
            if (state.cityArraySting !== '') {
              state.cityArraySting = state.cityArraySting + '，' + e.name
            } else {
              state.cityArraySting = e.name
            }
            state.cityCheckListCache.push(e.cityId)
          })
          state.cityCheckList = state.cityCheckList.concat(state.cityCheckListCache)
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }
    onMounted(() => {
      if (props.cityType) {
        state.cityType = props.cityType
        switch (props.cityType) {
          case 1:
            state.title = '一级城市（专线涉及）'
            break
          case 2:
            state.title = '二级城市（热门城市）'
            break
          case 3:
            state.title = '三级城市（常规城市）'
            break
          case 4:
            state.title = '四级城市（高速路口）'
            break
          case 5:
            state.title = '五级城市（无运力城市）'
            break
          default:
            state.title = '未定义的城市类型'
        }
        loadData()
      }
    })
    // 模糊搜索
    const filter = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }
    // 取消
    const reset = () => {
      state.show = false
      loadData()
    }
    // 确认
    const onOk = () => {
      state.loading = true
      edit({
        level: state.cityType,
        cityIdList: state.cityCheckList
      }).then(res => {
        if (res.code === 10000) {
          state.show = false
          message.success(res.msg)
          loadData()
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }

    // 遍历省获取城市
    const onProvinceList = (list) => {
      // let i = 0
      list.forEach(l => {
        state.startingAddress.forEach(e => {
          if (e.cityId === parseInt(l.value)) {
            state.sortSize = state.sortSize + 1
            e.sort = state.sortSize
          }
        })
      })
      state.startingAddress.sort(function (a, b) { return b.sort - a.sort })
    }
    const onCityList = (c) => {
      // let i = 0
      state.startingAddress.forEach(e => {
        if (e.cityId === parseInt(c)) {
          state.sortSize = state.sortSize + 1
          e.sort = state.sortSize
        }
      })
      state.startingAddress.sort(function (a, b) { return b.sort - a.sort })
    }
    // 遍历省获取城市
    const onStartingAddress = () => {
      loadData()
      list({
        levelList: [state.cityType, 0]
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data
          state.startingAddress = res.data
          state.startingAddress.forEach(s => { s.sort = 0 })
          onCityCheckListSting()
        }
      }).catch(err => {
        console.log(err)
      })
    }
    // 选择省
    const setStartAddress = (value, selectedOptions) => {
      if (value.length > 1) {
        onCityList(value[1])
      } else {
        state.cityList.forEach(e => {
          if (parseInt(e.value) === parseInt(value[0])) {
            onProvinceList(e.children)
          }
        })
      }
    }

    // 已经选择的城市
    const onCityCheckListSting = () => {
      state.listDataCheck = []
      state.listData.forEach(e => {
        state.cityCheckList.forEach(key => {
          if (e.cityId === key) {
            state.listDataCheck.push(e)
          }
        })
      })
    }

    // 监控是否选择了
    watch(
      () => state.cityCheckList,
      val => {
        state.indeterminate = !!val.length && val.length < state.startingAddress.length
        state.checkAll = val.length === state.startingAddress.length
      }
    )
    watch(
      () => state.searchForm.start,
      val => {
        if (state.searchForm.start.length === 0) {
          state.startingAddress = []
          state.startingAddress = state.startingAddress.concat(state.listData)
        }
      }
    )

    // 已经选择的城市
    const onCityCheckListChange = () => {
      console.log('onCityCheckListChange', state.cityCheckId, state.cityCheckList, state.cityCheckListCache)
      let isExist = false
      state.listDataCheck.forEach((e, index) => {
        if (e.cityId === state.cityCheckId) {
          state.listDataCheck.splice(index, 1)
          isExist = true
        }
      })
      if (!isExist) {
        state.listData.forEach(e => {
          if (e.cityId === state.cityCheckId) {
            state.listDataCheck.push(e)
          }
        })
      }
    }
    return {
      ...toRefs(state),
      loadData,
      filter,
      reset,
      onOk,
      onProvinceList,
      onCityList,
      setStartAddress,
      onStartingAddress,
      onCityCheckListSting,
      onCityCheckListChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
